import React, { Fragment } from "react";

export const About = () => {
  return (
    <Fragment>
      <h1>About this app</h1>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio, officiis
        quisquam amet error iste praesentium velit cupiditate dolorem autem
        alias eveniet. Molestias repellendus est earum eum ipsam! Quidem,
        voluptas soluta.
      </p>
      <p>Version 1.0.0</p>
    </Fragment>
  );
};

export default About;
